import { ReactComponent as WarningIcon } from "assets/images/field-error.svg";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { handleFoodTypeImage } from "central-utils/foodTypeImageUtils";
import { handleModifierDescription } from "central-utils/modifierStringUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import { homePageTabItems } from "central-utils/tabUtils";
import CustomLoader from "components/common/CustomLoader";
import CounterButton from "components/CounterButton";
import CustomToast from "components/CustomToast";
import OrderItemsBanner from "components/OrderItemsBanner";
import { usePriceChannel } from "context/pusher/price";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleFooterHeight } from "store/footer/footerSlice";
import {
  useAddMenuItemToOrderByOrderIdMutation,
  useGetMenuItemsForReorderByOrderIdQuery,
} from "store/orders/ordersAPISlice";
import { handleClearingOfPusherCart } from "store/tableStatusAndOrder/tableStatusAndOrderSlice";
import { IObject } from "types";

const ReorderPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pusherPriceData = usePriceChannel();

  const initialAPICallSuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );
  const initialOrderDetails = useAppSelector(
    (state) => state.tableStatusAndOrder.orderDetails
  );
  const { isOrderTimeClosed } = useAppSelector(
    (state) => state.tableStatusAndOrder
  );
  const orderId = sessionStorage.getItem("OrderId");

  useEffect(() => {
    if (isOrderTimeClosed) {
      sessionStorage.setItem(
        "activeOrderHeaderTab",
        JSON.stringify(homePageTabItems[0].id)
      );
      navigate("/home");
    }
  }, [isOrderTimeClosed, navigate]);

  const {
    data: reOrderData,
    isLoading: reOrderIsLoading,
    isError: reOrderIsError,
    isSuccess: reOrderIsSuccess,
    isFetching: reOrderIsFetching,
    refetch: refetchReOrderData,
  } = useGetMenuItemsForReorderByOrderIdQuery(
    { orderId: initialOrderDetails?.id ?? Number(orderId) },
    { skip: !initialAPICallSuccess }
  );

  const [addMenuItemToOrder] = useAddMenuItemToOrderByOrderIdMutation();

  // Func that handles Formatting of Reorder API Data
  const handleReOrderData = (apiData: any) => {
    let processedDetails: any = apiData
      ?.filter((item: any) => item?.status !== "NEW")
      ?.map((item: any) => {
        let description = "";

        if (item?.instructions?.length) {
          const instructionString = item?.instructions?.join(", ");
          description +=
            description !== "" ? `, ${instructionString}` : instructionString;
        }

        description += (description !== "" ? ", " : "") + handleModifierDescription(item);

        return {
          id: item?.id,
          displayName: item?.menuItemMaster?.displayName,
          foodType: item?.menuItemMaster?.foodType,
          servingSize: item?.menuItemMaster?.productVariant?.size?.size,
          unitPrice: item?.unitPrice,
          quantity: 0, // Initial quantity is zero
          description: description,
          helperData: item,
        };
      })
      ?.sort((a: any, b: any) =>
        (a?.displayName + " (" + a?.servingSize + " )")?.localeCompare((b?.displayName + " (" + b?.servingSize + " )"))
      );

    return processedDetails;
  };

  const [processedReOrderData, setProcessedReOrderData] = useState<IObject[]>(
    []
  );
  const [menuItemsToBeOrdered, setMenuItemsToBeOrdered] = useState<
    { menuItem: IObject; quantity: number; totalPrice: number }[]
  >([]);
  const [updateMenuItemPrice, setUpdateMenuItemPrice] =
    useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [noReorderDataMessage, setNoReorderDataMessage] = useState<
    string | null
  >(null);

  // Error Handling
  useEffect(() => {
    if (reOrderIsError) {
      dispatch(handleFooterHeight(70));
      setToastMessage("Oops! There was an error. Please try again.");
    }
  }, [dispatch, reOrderIsError]);

  // Formatting of Reorder API
  useEffect(() => {
    if (reOrderIsSuccess && reOrderData) {
      const transformedData = Object.keys(reOrderData.categorizedItems)
        .map((category) => ({
          categoryName: category,
          menuResponseDetails: handleReOrderData(
            reOrderData.categorizedItems[category]
          ),
        }))
        .sort((a, b) => a.categoryName.localeCompare(b.categoryName));
      if (!transformedData?.length) {
        setNoReorderDataMessage("Please place the order and confirm it!");
      }
      setProcessedReOrderData(transformedData);

      // Initialize the menuItemsToBeOrdered with 0 quantities and calculated total prices
      const initialMenuItems = transformedData.flatMap((categoryData: any) =>
        categoryData.menuResponseDetails.map((menuItem: any) => ({
          menuItem,
          quantity: 0,
          totalPrice: menuItem?.unitPrice,
        }))
      );

      setMenuItemsToBeOrdered(initialMenuItems);
    }
  }, [reOrderIsSuccess, reOrderData, dispatch]);

  // Handle quantity changes and update total price
  const handleOnQuantityChange = (quantity: number, menuItem: any) => {
    const unitPrice = menuItem.unitPrice;
    const newTotalPrice = quantity === 0 ? unitPrice : quantity * unitPrice; // Calculate the total price

    setMenuItemsToBeOrdered((prevMenuItems) => {
      const existingItem = prevMenuItems.find(
        (item) => item.menuItem.id === menuItem.id
      );
      if (existingItem) {
        return prevMenuItems.map((item) =>
          item.menuItem.id === menuItem.id
            ? { ...item, quantity, totalPrice: newTotalPrice }
            : item
        );
      } else {
        return [
          ...prevMenuItems,
          { menuItem, quantity, totalPrice: newTotalPrice },
        ];
      }
    });
  };

  // API Call and Redirection to Order Page on Success
  const handleConfirmOrder = async () => {
    dispatch(handleGlobalLoader(true));

    // Filter items with quantity > 0
    let initialFiltering = menuItemsToBeOrdered
      ?.map(
        (obj: any) =>
          obj.quantity !== 0 && {
            ...obj?.menuItem?.helperData,
            updatedQuantity: obj.quantity,
          }
      )
      ?.filter((item) => item !== false);

    // Check if there are items to order
    if (!initialFiltering?.length) {
      dispatch(handleGlobalLoader(false));
      setToastMessage("Please increase the quantity of the item!");
      dispatch(handleFooterHeight(130));
      return;
    }

    try {
      // Process all items and collect their API call results
      const apiResults = [];
      for (const obj of initialFiltering) {
        let payload: any = {
          menuItemMaster: {
            id: obj?.menuItemMaster?.id,
          },
          quantity: obj?.updatedQuantity,
          price: obj?.unitPrice * obj?.updatedQuantity,
        };

        if (obj?.instructions?.length) {
          payload.instructions = obj?.instructions;
        }

        if (obj?.modifiers?.length) {
          payload.modifiers = obj?.modifiers.map(
            (modifier: { id: number }) => ({ id: modifier.id })
          );
        }

        if (obj?.orderItemModifierQuantityMappings?.length) {
          payload.orderItemModifierQuantityMappings = obj?.orderItemModifierQuantityMappings?.map((mod: any) => ({
            modifierName: mod?.modifierName,
            modifierQuantity: mod?.modifierQuantity,
            orderItemCustomerModifierMappingId: mod?.orderItemCustomerModifierMappingId,
          }))
        }

        const res = await addMenuItemToOrder({
          orderId,
          menuItemsBody: payload,
        });

        apiResults.push(res);
      }

      // Check if all API calls were successful
      const allSuccessful = apiResults.every(res => res?.data);

      if (allSuccessful) {
        dispatch(handleClearingOfPusherCart(true));
        setMenuItemsToBeOrdered([]);
        navigate("/order", { state: { previousRoute: "reorder" } });
      } else {
        // Handle the first error we find
        const firstError = apiResults.find(res => !res?.data);
        const errMessage =
          firstError?.error?.data?.message ??
          firstError?.error?.error ??
          firstError?.error?.data?.error ??
          "Something went wrong while updating the quantity. Please try again!";

        setToastMessage(errMessage);
        dispatch(handleFooterHeight(130));
        refetchReOrderData();

        if (errMessage === "Order is not in NEW status. Cannot add or update items.") {
          navigate("/payment-in-progress-block");
        }
      }

    } catch (error) {
      console.error("Error during order confirmation: ", error);
      setToastMessage("Something went wrong while processing your order. Please try again!");
      dispatch(handleFooterHeight(130));
    } finally {
      dispatch(handleGlobalLoader(false));
    }
  };

  // Price Update Pusher
  useEffect(() => {
    if (
      pusherPriceData &&
      Object.keys(pusherPriceData ?? {})?.length &&
      Object.hasOwn(pusherPriceData, "isPriceUpdateFlag")
    ) {
      setUpdateMenuItemPrice(pusherPriceData?.isPriceUpdateFlag);
    }

    // Set a timer to reset the updateMenuItemPrice after 3 seconds
    const timer = setTimeout(() => {
      setUpdateMenuItemPrice(false);
    }, 3000);

    // Clean up the timer when the component unmounts or when pusherPriceData changes
    return () => clearTimeout(timer);
  }, [pusherPriceData]);

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    dispatch(handleFooterHeight(70));
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };

  // Render a Message when there's no order placed yet
  const renderNoReorderData = () => {
    if (noReorderDataMessage) {
      return (
        <div className="flex flex-col items-center justify-center h-full pageInfoMessage">
          <div className="warningIcon iconColor">
            <WarningIcon className="mb-[10px]" />
          </div>
          <div>{noReorderDataMessage}</div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {(reOrderIsLoading || reOrderIsFetching) && (
        <CustomLoader isLoading={reOrderIsLoading || reOrderIsFetching} />
      )}

      {reOrderIsSuccess && reOrderData && (
        <div className="reorderPage">
          {processedReOrderData && processedReOrderData.length
            ? processedReOrderData.map((reorderItem: any, index: number) => {
              return (
                <div
                  key={"a" + reorderItem?.id}
                  className="last:mb-[70px] categoryItem"
                >
                  <div className="categoryName first:mt-[10px] py-[10px]">
                    {convertToCapitalizedString(reorderItem?.categoryName)}
                  </div>
                  <div className="menuItemContainer">
                    {reorderItem?.menuResponseDetails?.map(
                      (orderItem: any) => {
                        const {
                          id,
                          foodType,
                          displayName,
                          servingSize,
                          description,
                          unitPrice,
                        } = orderItem;
                        const foodTypeImage = handleFoodTypeImage(foodType);
                        const itemToOrder = menuItemsToBeOrdered.find(
                          (item) => item.menuItem.id === orderItem.id
                        );
                        const initialQuantity = itemToOrder ? itemToOrder.quantity : 0;
                        const totalPrice = itemToOrder
                          ? itemToOrder.totalPrice
                          : unitPrice;

                        return (
                          <div
                            key={"b" + id}
                            className="w-full flex justify-between items-center py-[15px] menuItem"
                          >
                            <div className="w-1/2">
                              <div className="ml-[10px] flex items-center">
                                {foodTypeImage && (
                                  <img
                                    className="foodTypeImage mr-[10px]"
                                    src={foodTypeImage}
                                    alt={foodType || "Food type image"}
                                  />
                                )}
                                <span>
                                  {convertToCapitalizedString(displayName)}
                                  <span className="whitespace-nowrap">{` (${servingSize})`}</span>
                                </span>
                              </div>
                              {description && (
                                <p
                                  className={`itemDescription break-words mt-1 ${foodTypeImage ? "ml-[30px]" : "ml-[10px]"
                                    }`}
                                >
                                  {convertToCapitalizedString(description)}
                                </p>
                              )}
                            </div>
                            <div className="flex flex-col items-center mr-[10px]">
                              <CounterButton
                                buttonType="SMALL"
                                initialQuantity={initialQuantity}
                                onQuantityChange={(quantity) =>
                                  handleOnQuantityChange(quantity, orderItem)
                                }
                              />
                              <div className="itemPrice mt-[10px] max-w-[60px] w-[60px] text-center">
                                {rupeeFormatter(totalPrice)}
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              );
            })
            : ""}
          {reOrderData !== undefined &&
            processedReOrderData &&
            processedReOrderData?.length > 0 && (
              <div className="flex items-center justify-center">
                <button
                  onClick={() => handleConfirmOrder()}
                  className="fixed text-center z-10 addToCartButton"
                >
                  Add to Cart
                </button>
              </div>
            )}
          {updateMenuItemPrice && <OrderItemsBanner bannerType="PRICE_ALERT" />}
        </div>
      )}
      {renderNoReorderData()}
      {renderToast()}
    </>
  );
};

export default ReorderPage;
