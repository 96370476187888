import { ReactComponent as PendingIcon } from "assets/images/pending-orders.svg";
import React from 'react';

const SessionExpiredPage: React.FC = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full pageInfoMessage text-center mx-10">
            <div className="pendingIcon">
                <PendingIcon width={44} height={45} className='mb-[20px]' />
            </div>
            <div className="pageInfoHeader">Your session has expired, scan to start again!</div>
        </div>
    )
}

export default SessionExpiredPage