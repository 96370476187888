import { ReactComponent as BellIcon } from "assets/images/bell.svg";
import { useOrderTimings } from "context/order-timings/OrderTimingsContext";
import React from "react";

const LastCall: React.FC = () => {
  const { remainingTime, isVisible } = useOrderTimings();

  if (!isVisible) {
    return null;
  }

  const minutes = Math.floor((remainingTime ?? 0) / 60);
  const seconds = (remainingTime ?? 0) % 60;
  const timeDisplay = `${minutes}m:${seconds.toString().padStart(2, "0")}s`;
  console.log('timeDisplay', timeDisplay)
  return (
    <div className="lastCallContainer mt-[20px] p-[10px] flex justify-between items-center">
      <div className="flex w-3/4">
        <div className="bellIconContainer flex items-center justify-center">
          <BellIcon className="bellIcon" />
        </div>
        <div className="lastCallText ml-[10px]">
          <div className="lastCallHeader">{timeDisplay === "0m:00s" ? "Time’s Up!" : "Last Call!"}</div>
          <div className="lastCallDescription">
            {
              timeDisplay === "0m:00s" ?
                "No more orders can be placed now!" :
                "Time’s almost up—place your final orders before the bar closes!"
            }
          </div>
        </div>
      </div>
      <div className="lastCallTimeContainer pl-[10px]">
        <div className="lastCallTime">{timeDisplay}</div>
        <div className="lastCallDescription">Time Left</div>
      </div>
    </div>
  );
};

export default LastCall;
