import { useEffect } from "react";

const BASE_URL = process.env.REACT_APP_BASE_URL || "";
const fallbackCssPath = "/styles/default.css";

export const useLoadCss = () => {
    useEffect(() => {
        // Function to extract brandId from query parameters
        function getBrandId() {
          const queryParams = new URLSearchParams(window.location.search);
          return queryParams.get("brandId");
        }
      
        // Function to fetch CSS URL based on brandId
        async function fetchCSSUrl(brandId: string) {
          const apiURL = `${BASE_URL}/color-setting/tenant/${brandId}`;
          try {
            const response = await fetch(apiURL);
            if (!response.ok) throw new Error("Failed to fetch CSS settings");
      
            const data = await response.json();
            const cssUrl = data?.file?.url || null;
            const fontFamily = data?.fontFamily || "Roboto Condensed, sans-serif";
            const headerLogo = data?.primaryImage?.url || "";
            const defaultHeaderLogo = data?.secondaryImage?.url || "";
      
            // Store CSS URL in sessionStorage
            if (cssUrl) {
              sessionStorage.setItem(
                "CustomerApp-Styles",
                JSON.stringify({
                  cssUrl: cssUrl,
                  fontFamily: fontFamily,
                  defaultHeaderLogo: defaultHeaderLogo,
                  headerLogo: headerLogo,
                })
              );
            }
            return cssUrl;
          } catch (error) {
            console.error("Error fetching CSS URL:", error);
            return null;
          }
        }
      
        // Function to dynamically load a CSS file
        function loadStylesheet(url: string) {
          return new Promise<void>((resolve, reject) => {
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = url;
            link.onload = () => resolve();
            link.onerror = () => reject(`Failed to load stylesheet: ${url}`);
            document.head.appendChild(link);
          });
        }
      
        // Main function to initialize styles
        async function initializeStyles() {
          const brandId = getBrandId();
          let storedSettings = sessionStorage.getItem("CustomerApp-Styles");
          let cssUrl = storedSettings ? JSON.parse(storedSettings).cssUrl : null;
      
          if (!cssUrl && brandId) {
            cssUrl = await fetchCSSUrl(brandId);
          }
      
          try {
            if (cssUrl) {
              // Load the fetched CSS file
              await loadStylesheet(cssUrl);
              console.log("Custom stylesheet loaded:", cssUrl);
            } else {
              // Load the fallback CSS file
              await loadStylesheet(fallbackCssPath);
              console.warn(
                "No custom stylesheet available, loaded fallback:",
                fallbackCssPath
              );
            }
          } catch (err) {
            console.error(err);
          }
        }
      
        // Call the function on component mount
        initializeStyles();
      }, []);
}
