import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import type { RootState } from "store";
import { handleLogInCredentials, handleLogInState } from "store/auth/authSlice";

let takeRefreshToken = false;

const BASE_URL = process.env.REACT_APP_BASE_URL as string;
const BASE_URL_SQL = process.env.REACT_APP_BASE_URL_SQL as string;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL_SQL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const state = getState() as RootState;

    // Access token and expiry
    let token = sessionStorage.getItem("accessToken");
    const accessTokenExpiry = Number(
      sessionStorage.getItem("accessTokenExpiry")
    );

    // Refresh token and expiry
    let refreshToken = sessionStorage.getItem("refreshToken");
    const refreshTokenExpiry = Number(
      sessionStorage.getItem("refreshTokenExpiry")
    );

    const currentTime = Date.now();

    // Check if access token has expired
    if (token && accessTokenExpiry && currentTime > accessTokenExpiry) {
      token = null;
      console.info("Access token has expired");
    }

    // Check if refresh token has expired
    if (
      refreshToken &&
      refreshTokenExpiry &&
      currentTime > refreshTokenExpiry
    ) {
      refreshToken = null;
      console.info("Refresh token has expired");
      // api.dispatch(handleLogOut());
      // window.location.href = "/";
      // window.location.reload();
    }

    // Attempt to use access token first
    if (!takeRefreshToken && token) {
      headers.set("Authorization", token);
    } else if (takeRefreshToken && refreshToken) {
      headers.set("refreshToken", refreshToken);
    }
    // Skip setting X-Tenant for login and tenant-related endpoints
    if (!["login", "refreshLogin", "getTenantById"].includes(endpoint)) {
      const tenant = JSON.parse(
        sessionStorage.getItem("CustomerApp-Tenant") || "{}"
      );
      if (tenant && tenant?.databaseName) {
        headers.set("X-Tenant", tenant.databaseName);
      }
    }

    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  takeRefreshToken = false;
  const state = api.getState() as RootState;
  let result = await baseQuery(args, api, extraOptions);
  console.log("[api] result", result);
  // if (result?.error?.status === 401 && !state.auth.logOutState) {
  //   window.location.href = "/session-out";
  //   // Access token might be expired, try refreshing it
  //   takeRefreshToken = true;

  //   const refreshResult = await baseQuery(
  //     {
  //       url: `${BASE_URL}/login/refresh`,
  //       method: "POST",
  //       body: {},
  //     },
  //     api,
  //     extraOptions
  //   );

  //   if (refreshResult?.data) {
  //     // Store the new tokens and their expiry
  //     api.dispatch(handleLogInCredentials({ data: { ...refreshResult.data } }));

  //     // Retry the original query with new access token
  //     takeRefreshToken = false;
  //     result = await baseQuery(args, api, extraOptions);
  //   } else {
  //     console.info("Refresh token failed, logging out...");
  //     // api.dispatch(handleLogOut());
  //     // window.location.href = "/";
  //     // window.location.reload();
  //   }
  // } else

  if (result?.error?.status === 401) {
    window.location.href = "/session-out";
  } else if (result?.data) {
    api.dispatch(handleLogInState());
  }
  return result;
};

// Handle API slice with base query
export const apiSlice = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({}),
});

export type ApiSlice = typeof apiSlice;
