import { ConfigProvider } from "antd";
import "App.css";
import CustomLoader from "components/common/CustomLoader";
import React, { useEffect } from "react";
import RouteCenter from "routes";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import "styles/index.scss";
import { handleHeaderLogo } from "store/header/headerSlice";
import { useLoadCss } from "hooks/useLoadCss";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const globalLoader = useAppSelector((state) => state.auth.globalLoader);
  const tenant = JSON.parse(
    sessionStorage.getItem("CustomerApp-Tenant") || "{}"
  );

  const tenantStyles = JSON.parse(
    sessionStorage.getItem("CustomerApp-Styles") || "{}"
  );

  useLoadCss();

  useEffect(() => {
    dispatch(
      handleHeaderLogo({
        defaultHeaderLogo: tenantStyles.defaultHeaderLogo,
        headerLogo: tenantStyles.headerLogo,
        altName: tenant?.tenantName || "default",
      })
    );
  }, [tenantStyles, tenant]);

  return (
    <React.Fragment>
      <ConfigProvider
        theme={{
          token: {
            fontFamily:
              tenantStyles.fontFamily || "Roboto Condensed, sans-serif",
          },
        }}
      >
        {globalLoader && <CustomLoader isLoading={globalLoader} />}
        <div className="typography">
          <RouteCenter />
        </div>
      </ConfigProvider>
    </React.Fragment>
  );
};

export default App;
