import { ReactComponent as BackArrow } from "assets/images/back-arrow.svg";
import { ReactComponent as Cancel } from "assets/images/cancel.svg";
import { ReactComponent as OrderIcon } from "assets/images/order.svg";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  handleClearLocalSearchQuery,
  handleSearchQuery,
} from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import { handleClearingOfPusherCart } from "store/tableStatusAndOrder/tableStatusAndOrderSlice";

const SearchBarHeader: React.FC = () => {
  const navigate = useNavigate();

  const inputRef = useRef<HTMLInputElement>(null); // Create a reference for the input

  const dispatch = useAppDispatch();
  const { searchQuery, clearLocalSearchQuery } = useAppSelector(
    (state) => state.globalSearchAndFilters
  );
  const unconfirmedOrderItemsCount = useAppSelector(
    (state) => state.footer.unconfirmedOrderItems
  );

  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  // Use useEffect to focus on the input when the component mounts
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input
    }
  }, []);

  useEffect(() => {
    if (clearLocalSearchQuery) {
      setDebouncedQuery("");
      dispatch(handleClearLocalSearchQuery(false));
    }
  }, [clearLocalSearchQuery, dispatch]);

  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(handleSearchQuery(debouncedQuery));
    }, 1000);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedQuery, dispatch]);

  const handleBackButton = () => {
    dispatch(handleClearingOfPusherCart(true));
    navigate("/order")
  }

  return (
    <div className="searchBarHeader p-2 flex justify-between items-center">
      <div className="flex items-center searchBox space-x-2">
        {/* Back Arrow */}
        <BackArrow
          className="ml-[10px] icon"
          onClick={() => {
            setDebouncedQuery("");
            dispatch(handleSearchQuery(""));
            navigate(-1);
          }}
        />

        {/* Search Input */}
        <input
          ref={inputRef}
          type="text"
          placeholder="Search All Items"
          className="mx-[10px] searchInput flex-grow"
          value={debouncedQuery}
          onChange={(e) => {
            const inputValue = e.target.value;
            // Updated regex to allow letters number and spaces
            const validInput = /^[A-Za-z0-9\s]+$/;

            // Check if the input value matches the allowed pattern or is empty
            if (inputValue === "" || validInput.test(inputValue)) {
              setDebouncedQuery(inputValue);
            }
          }}
        />

        {/* Close Button */}
        <div className="pr-2">
          <Cancel
            onClick={() => {
              dispatch(handleClearLocalSearchQuery(true));
              if (inputRef.current) {
                inputRef.current.focus(); // Set focus back to the input
              }
            }}
            className="icon"
          />
        </div>
      </div>
      <div
        onClick={handleBackButton}
        className="relative p-3">
        <OrderIcon
          className="icon"
        />
        {unconfirmedOrderItemsCount > 0 && (
          <div className="orderCountDisplay rounded-full absolute left-5 bottom-5 flex items-center justify-center">
            {unconfirmedOrderItemsCount}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBarHeader;
