import InitialDataFetcher from "components/InitialDataFetcher";
import OrderTimingsProvider from "context/order-timings/OrderTimingsContext";
import OrderProvider from "context/order/OrderContext";
import PusherProvider from "context/pusher";
import CartChannelProvider from "context/pusher/cart";
import CheckoutChannelProvider from "context/pusher/checkout";
import CustomerLogoutChannelProvider from "context/pusher/customer-logout";
import PriceChannelProvider from "context/pusher/price";
import TableChangeChannelProvider from "context/pusher/table-change";
import TableVerificationChannelProvider from "context/pusher/table-verification";
import VoidItemChannelProvider from "context/pusher/void-item";
import VoidOrderChannelProvider from "context/pusher/void-order";
import WaiterChannelProvider from "context/pusher/waiter";
import MainLayout from "layout";
import BillPendingPage from "pages/bill-pending";
import CheckoutPage from "pages/checkout-details-page";
import CustomerDetailsPage from "pages/customer-details-page";
import EnterNamePage from "pages/enter-name-page";
import HomePage from "pages/home-page";
import OrdersPage from "pages/order-details-page";
import OtpVerificationPage from "pages/otp-verification-page";
import PaymentConfirmationPage from "pages/payment-confirmation-page";
import PaymentInProgressBlockPage from "pages/payment-in-progress-block-page";
import PaymentStatusPage from "pages/payment-status-page";
import PromotionsDetailsPage from "pages/promotion-details-page";
import ReorderPage from "pages/reorder-page";
import SearchPage from "pages/search-page";
import SessionExpiredPage from "pages/session-expired-page";
import TablePinVerificationPage from "pages/table-pin-verification-page";
import TermsAndConditionsPage from "pages/terms-and-conditions-page";
import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

const RouteCenter: React.FC = () => {
  return (
    <Router>
      <InitialDataFetcher />
      <PusherProvider>
        <OrderTimingsProvider>
          <TableChangeChannelProvider>
            <TableVerificationChannelProvider>
              <PriceChannelProvider>
                <CartChannelProvider>
                  <CheckoutChannelProvider>
                    <WaiterChannelProvider>
                      <CustomerLogoutChannelProvider>
                        <VoidOrderChannelProvider>
                          <VoidItemChannelProvider>
                            <OrderProvider>
                              <MainLayout>
                                <Routes>
                                  <Route
                                    path="/"
                                    element={<CustomerDetailsPage />}
                                  />
                                  <Route
                                    path="/verify-otp"
                                    element={<OtpVerificationPage />}
                                  />
                                  <Route
                                    path="/enter-name"
                                    element={<EnterNamePage />}
                                  />
                                  <Route
                                    path="/verify-pin"
                                    element={<TablePinVerificationPage />}
                                  />
                                  <Route path="/home" element={<HomePage />} />
                                  <Route
                                    path="/search"
                                    element={<SearchPage />}
                                  />
                                  <Route path="/order" element={<OrdersPage />} />
                                  <Route
                                    path="/re-order"
                                    element={<ReorderPage />}
                                  />
                                  <Route
                                    path="/bill-pending"
                                    element={<BillPendingPage />}
                                  />
                                  <Route
                                    path="/check-out"
                                    element={<CheckoutPage />}
                                  />
                                  <Route
                                    path="/payment-confirmation"
                                    element={<PaymentConfirmationPage />}
                                  />
                                  <Route
                                    path="/payment-in-progress-block"
                                    element={<PaymentInProgressBlockPage />}
                                  />
                                  <Route
                                    path="/payment-status"
                                    element={<PaymentStatusPage />}
                                  />
                                  <Route
                                    path="/promotion-details"
                                    element={<PromotionsDetailsPage />}
                                  />
                                  <Route
                                    path={`/terms/:type`}
                                    element={<TermsAndConditionsPage />}
                                  />
                                  <Route
                                    path="/session-expired"
                                    element={<SessionExpiredPage />}
                                  />
                                </Routes>
                              </MainLayout>
                            </OrderProvider>
                          </VoidItemChannelProvider>
                        </VoidOrderChannelProvider>
                      </CustomerLogoutChannelProvider>
                    </WaiterChannelProvider>
                  </CheckoutChannelProvider>
                </CartChannelProvider>
              </PriceChannelProvider>
            </TableVerificationChannelProvider>
          </TableChangeChannelProvider>
        </OrderTimingsProvider>
      </PusherProvider>
    </Router>
  );
};

export default RouteCenter;
