import { Modal } from "antd";
import { ReactComponent as ModalClose } from "assets/images/modal-close-icon.svg";
import { handleValuesAreDiff } from "central-utils/comparisonUtils";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import { handleValidImageUrl } from "central-utils/validImageUrlUtils";
import CustomLoader from "components/common/CustomLoader";
import CounterButton from "components/CounterButton";
import CustomToast from "components/CustomToast";
import MenuItemCustomizationCheckbox from "components/menu-item-customization-modal/MenuItemCustomizationCheckbox";
import { useOrder } from "context/order/OrderContext";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { useGetMenuItemByProductIdAndBranchIdQuery } from "store/menuCustomization/menuCustomizationAPISlice";
import {
  getMenuCustomizationRelatedStates,
  handleClearMenuCustomizations,
  handleMenuItemBannerDisplay,
  handleMenuItemsBannerType,
} from "store/menuCustomization/menuCustomizationSlice";
import {
  useAddMenuItemToOrderByOrderIdMutation,
  useUpdateOrderItemInOrderByOrderItemIdMutation,
} from "store/orders/ordersAPISlice";
import { IObject } from "types";

interface Option {
  id?: number | string;
  label: string;
  price?: number;
  helperData?: { [key: string]: any };
  autoSelected: boolean;
  hideOption: boolean;
  initialQuantity?: number;
}

type CheckBoxType = "SINGLE_SELECT" | "MULTI_SELECT";

interface IComponentPropDetail {
  bodyHeader: string;
  isRequired: boolean;
  mainHeader: string;
  checkBoxType: CheckBoxType;
  editData: IObject;
  minValue: number | null;
  maxValue: number | null;
}

const MenuItemCustomizationModal: React.FC<{
  type?: "ADD" | "EDIT";
  editData?: IObject;
  productMasterId: number;
  onClose: () => void;
  setAddItemQuantity?: (quantity: number) => void;
}> = ({
  productMasterId,
  onClose,
  type = "ADD",
  editData,
  setAddItemQuantity,
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { orderIsUninitialized, refetchOrderData } = useOrder();
    const modalRef = useRef<HTMLDivElement>(null);
    let touchStartY = useRef(0);

    const [prevSelectedData, setPrevSelectedData] = useState<any>([]);
    const [minMaxModValue, setMinMaxValue] = useState<{
      minValue: number | null;
      maxValue: number | null;
    } | null>(null);
    const [disableAddButton, setDisableAddButton] = useState<boolean>(false);
    const [toastMessage, setToastMessage] = useState<string | null>(null);
    const [removeToast, setRemoveToast] = useState<boolean>(true);
    const [menuQuantity, setMenuQuantity] = useState(
      type === "EDIT" ? Number(editData?.quantity) : 1
    );

    useEffect(() => {
      if (editData && Object?.keys(editData)?.length > 0) {
        const prevData = {
          ...selectedMenuCustomizationOptions,
        };
        setPrevSelectedData(prevData);
      }
    }, [editData]);

    const initialAPICallSuccess = useAppSelector(
      (state) => state.auth.initialAPISuccess
    );

    const ids = sessionStorage.getItem("Ids");
    const parsedIds = ids && JSON.parse(ids);
    const orderId = JSON.parse(sessionStorage.getItem("OrderId") || "{}");

    const [selectedPrice, setSelectedPrice] = useState(0);

    const {
      data: menuDetailData,
      isLoading: menuDetailIsLoading,
      isError: menuDetailIsError,
      isSuccess: menuDetailIsSuccess,
      isFetching: menuDetailIsFetching,
    } = useGetMenuItemByProductIdAndBranchIdQuery(
      { productMasterId, branchId: parsedIds?.branchId },
      { skip: !initialAPICallSuccess }
    );

    const [addMenuItemToOrder] = useAddMenuItemToOrderByOrderIdMutation();
    const [updateOrderItem] = useUpdateOrderItemInOrderByOrderItemIdMutation();

    const handleCustomizationSectionData = (type: string): Option[] => {
      switch (type) {
        case "Serving Size":
          return (
            menuDetailData?.menuItems.map((item: any) => ({
              id: item?.menuItemMasterId,
              label: item?.productVariant?.size?.size,
              price: item?.branchSettings?.price ?? undefined,
              autoSelected: false,
              helperData: item,
              hideOption: false,
            })) || []
          );
        case "Recommendations":
          return (
            menuDetailData?.recommendations?.map((item: any) => ({
              id: item.id,
              label: `${item?.displayName} (${item?.servingSize})`,
              price: item?.price ?? undefined,
              autoSelected: false,
              helperData: item,
              hideOption: false,
            })) || []
          );
        case "Instructions":
          return (
            menuDetailData?.instructions?.map((item: any, index: number) => ({
              id: index,
              label: item,
              autoSelected: false,
              helperData: item,
              hideOption: false,
            })) || []
          );
        case "Extras & Add-ons":
          return (
            menuDetailData?.modifiers?.map((item: any) => ({
              id: item?.id,
              label: item?.modifier?.itemName,
              price: item?.price ?? undefined,
              helperData: item,
              autoSelected: item?.autoSelect,
              hideOption: false,
              initialQuantity: 0,
            })) || []
          );
        default:
          return [];
      }
    };

    const handleCustomizationEditData = (type: string): Option[] | [] => {
      switch (type) {
        case "Serving Size":
          return editData?.menuItemMaster?.id
            ? [
              {
                id: editData?.menuItemMaster?.id,
                label: editData?.menuItemMaster?.productVariant?.size?.size,
                price: editData?.branchSettings?.price ?? undefined,
                helperData: editData?.menuItemMaster,
                autoSelected: false,
                hideOption: false,
              },
            ]
            : [];
        case "Instructions":
          return editData?.instructions?.length > 0
            ? editData?.instructions?.map((item: any, index: number) => ({
              id: index,
              label: item,
              helperData: item,
              autoSelected: false,
              hideOption: false,
            }))
            : [];
        case "Extras & Add-ons":
          return editData?.modifiers?.length > 0
            ? editData?.modifiers?.map((item: any) => ({
              id: item?.id,
              label: item?.modifier?.itemName,
              price: item?.price ?? undefined,
              helperData: item,
              autoSelected: item?.autoSelect,
              hideOption: false,
              initialQuantity: item?.modifierQuantity,
              quantity: item?.modifierQuantity,
            }))
            : [];
        default:
          return [];
      }
    };

    const defaultComponentPropDetail: IComponentPropDetail[] = useMemo(() => {
      // Determine the appropriate bodyHeader based on minMaxModValue
      const getBodyHeader = () => {
        if (!minMaxModValue) return ""; // No minMaxModValue provided

        const { minValue, maxValue } = minMaxModValue;

        if (minValue !== null && maxValue !== null) {
          if (minValue === maxValue) {
            return `Required*: Please select any ${minValue} add on${minValue === 1 ? "" : "s"
              }.`;
          } else {
            return `Required*: Please select ${minValue} upto ${maxValue} add on${maxValue === 1 ? "" : "s"
              }.`;
          }
        } else if (minValue !== null) {
          return `Required*: Select ${minValue} from the add ons.`;
        } else if (maxValue !== null) {
          return `Required*: Select up to ${maxValue} from the add ons.`;
        }

        return "";
      };

      const bodyHeader = getBodyHeader(); // Get the dynamically generated header

      return [
        {
          bodyHeader: "",
          isRequired: true,
          mainHeader: "Serving Size",
          checkBoxType: "SINGLE_SELECT",
          editData:
            type === "EDIT"
              ? handleCustomizationEditData("Serving Size")
              : handleCustomizationSectionData("Serving Size"),
          maxValue: null,
          minValue: null,
        },
        {
          bodyHeader: bodyHeader,
          isRequired: false,
          mainHeader: "Extras & Add-ons",
          checkBoxType: "MULTI_SELECT",
          editData:
            type === "EDIT"
              ? handleCustomizationEditData("Extras & Add-ons")
              : handleCustomizationSectionData("Extras & Add-ons"),
          minValue: minMaxModValue ? minMaxModValue?.minValue : null,
          maxValue: minMaxModValue ? minMaxModValue?.maxValue : null,
        },
        {
          bodyHeader: "",
          isRequired: false,
          mainHeader: "Instructions",
          checkBoxType: "MULTI_SELECT",
          editData:
            type === "EDIT"
              ? handleCustomizationEditData("Instructions")
              : handleCustomizationSectionData("Instructions"),
          maxValue: null,
          minValue: null,
        },
      ];
    }, [minMaxModValue, type]);

    const componentPropDetails: IComponentPropDetail[] =
      type === "EDIT"
        ? defaultComponentPropDetail
        : [
          defaultComponentPropDetail?.[0],
          defaultComponentPropDetail?.[1],
          {
            bodyHeader: "",
            isRequired: false,
            mainHeader: "Recommendations",
            checkBoxType: "MULTI_SELECT",
            editData: handleCustomizationSectionData("Recommendations"),
            maxValue: null,
            minValue: null,
          },
          defaultComponentPropDetail?.[2],
        ];

    const handleCloseModal = () => {
      dispatch(handleClearMenuCustomizations());
      !orderIsUninitialized && refetchOrderData();
      onClose();
    };

    useEffect(() => {
      if (menuDetailIsError) {
        setToastMessage("Something went wrong while fetching details!");
        handleCloseModal();
      }
    }, [menuDetailIsError]);

    const { selectedMenuCustomizationOptions } = useAppSelector(
      getMenuCustomizationRelatedStates
    );

    useEffect(() => {
      if (menuQuantity === 0) {
        setToastMessage("Please increase the quantity of the item!");
        return;
      }
      const totalPrice = handleSelectedPrice(
        selectedMenuCustomizationOptions,
        menuQuantity,
        minMaxModValue
      );
      setSelectedPrice(totalPrice);
    }, [menuQuantity, minMaxModValue, selectedMenuCustomizationOptions]);

    useEffect(() => {
      const { servingSize } = selectedMenuCustomizationOptions;
      const findMenuItemsWithMinMaxMod = menuDetailData?.menuItems?.find(
        (menuItem: any) => menuItem?.menuItemMasterId === servingSize?.[0]?.id
      );
      if (findMenuItemsWithMinMaxMod) {
        if (
          !findMenuItemsWithMinMaxMod?.branchSettings?.minModifier ||
          !findMenuItemsWithMinMaxMod?.branchSettings?.maxModifier
        ) {
          setMinMaxValue({
            minValue:
              findMenuItemsWithMinMaxMod?.branchSettings?.minModifiers ===
                undefined
                ? null
                : findMenuItemsWithMinMaxMod?.branchSettings?.minModifiers,
            maxValue:
              findMenuItemsWithMinMaxMod?.branchSettings?.maxModifiers ===
                undefined
                ? null
                : findMenuItemsWithMinMaxMod?.branchSettings?.maxModifiers,
          });
        } else {
          setMinMaxValue(null);
        }
      }
    }, [menuDetailData?.menuItems, selectedMenuCustomizationOptions]);

    useEffect(() => {
      if (selectedMenuCustomizationOptions?.["extras&AddOns"]?.length) {
        setRemoveToast(false);
      }
    }, [selectedMenuCustomizationOptions?.["extras&AddOns"]]);

    useEffect(() => {
      if (minMaxModValue && selectedMenuCustomizationOptions) {
        const { recommendations, servingSize, instructions, ...extrasAndAddOns } =
          selectedMenuCustomizationOptions;

        if (
          extrasAndAddOns["extras&AddOns"] &&
          Array.isArray(Object.values(extrasAndAddOns["extras&AddOns"]))
        ) {
          const extrasAddOnsArray = Object.values(
            extrasAndAddOns["extras&AddOns"]
          );
          console.log("extrasAddOnsArray", extrasAddOnsArray);
          // Check if all items are auto-selected
          const allAutoSelected =
            extrasAddOnsArray.every((item: any) => item.autoSelected) || false;
          const extrasAndAddOnsQuantity = extrasAddOnsArray.reduce(
            (sum: number, item: any) => {
              return sum + (item.autoSelected ? 1 : item.quantity || 0);
            },
            0
          );

          const disableCheck =
            (minMaxModValue.minValue !== null &&
              extrasAndAddOnsQuantity < minMaxModValue.minValue) ||
            (minMaxModValue.maxValue !== null &&
              extrasAndAddOnsQuantity > minMaxModValue.maxValue);

          if (disableCheck && !removeToast && !allAutoSelected) {
            if (
              minMaxModValue.minValue !== null &&
              extrasAndAddOnsQuantity < minMaxModValue.minValue
            ) {
              setToastMessage(
                `Please select ${minMaxModValue.minValue} add on${minMaxModValue.minValue > 1 ? "s" : ""
                }.`
              );
            } else if (
              minMaxModValue.maxValue !== null &&
              extrasAndAddOnsQuantity > minMaxModValue.maxValue
            ) {
              setToastMessage(
                `Please select from ${minMaxModValue.maxValue} add on${minMaxModValue.maxValue > 1 ? "s" : ""
                }.`
              );
            }
          } else {
            setToastMessage(null);
          }
          setDisableAddButton(disableCheck);
        }
      }
    }, [minMaxModValue, selectedMenuCustomizationOptions, removeToast]);

    const handleQuantityUpdate = (quantity: number) => {
      setMenuQuantity(quantity);
    };

    const handleSubmit = async () => {
      if (!selectedMenuCustomizationOptions?.servingSize?.length) {
        setToastMessage("Please select a serving size before adding the item!");
        return;
      }

      // Show global loader
      dispatch(handleGlobalLoader(true));

      // Extract necessary fields
      const { recommendations, servingSize, instructions, ...extrasAndAddOns } =
        selectedMenuCustomizationOptions;
      const servingSizeId = servingSize?.[0]?.id;

      // Build the payload
      let customizedMenuItemPayload: any = {
        quantity: menuQuantity,
        menuItemMaster: {
          id: servingSizeId,
        },
        price: selectedPrice,
      };

      if (extrasAndAddOns?.["extras&AddOns"]?.length) {
        const isMinMaxNotAvailable =
          minMaxModValue === null ||
          (minMaxModValue?.maxValue === null &&
            minMaxModValue?.minValue === null);
        if (!isMinMaxNotAvailable) {
          customizedMenuItemPayload.orderItemModifierQuantityMappings =
            extrasAndAddOns["extras&AddOns"].map(
              (obj: {
                id: number;
                quantity: number;
                label: string;
                autoSelected: boolean;
              }) => ({
                orderItemCustomerModifierMappingId: obj.id,
                modifierQuantity: obj.autoSelected ? 1 : obj.quantity,
                modifierName: obj.label,
              })
            );
        }
        customizedMenuItemPayload.modifiers = extrasAndAddOns[
          "extras&AddOns"
        ].map((obj: { id: number }) => ({ id: obj.id }));
      }

      if (instructions?.length) {
        customizedMenuItemPayload.instructions = instructions.map(
          (obj: any) => obj.label
        );
      }

      const customerDetails = JSON.parse(
        sessionStorage.getItem("CustomerDetails") || "{}"
      );
      if (customerDetails && Object.keys(customerDetails).length) {
        customizedMenuItemPayload.customerId = customerDetails.id;
      }

      if (type === "ADD") {
        try {
          // Add the customized menu item to the order
          const res = await addMenuItemToOrder({
            orderId,
            menuItemsBody: customizedMenuItemPayload,
          });

          if (res?.data) {
            console.log("[customized menu order add] res?.data :>> ", res?.data);
          } else {
            throw new Error(
              res?.error?.data?.message ??
              res?.error?.error ??
              res?.error?.data?.error ??
              `Something went wrong while adding ${menuDetailData?.displayName ??
              menuDetailData?.productMaster?.itemName
              }. Please try again!`
            );
          }

          let recommendationsAdded: number = 0;
          // Process recommendations if any
          if (recommendations?.length) {
            const recommendationPromises = recommendations.map((recc: any) =>
              addMenuItemToOrder({
                orderId,
                menuItemsBody: {
                  quantity: 1,
                  menuItemMaster: { id: recc?.id },
                  price: recc?.price ? recc?.price : 0,
                },
              })
            );

            const recommendationResults = await Promise.all(
              recommendationPromises
            );

            recommendationResults.forEach((result: any) => {
              if (result?.data) {
                recommendationsAdded++;
                console.log(
                  "[recommendations menu order add] res?.data :>> ",
                  result?.data
                );
              }
            });
          }

          if (setAddItemQuantity) {
            setAddItemQuantity(menuQuantity + recommendationsAdded);
            dispatch(handleMenuItemsBannerType("ADD"));
            dispatch(handleMenuItemBannerDisplay(true));
          }
        } catch (error: any) {
          console.error("Error during ADD operation: ", error);
          setToastMessage(error.message);
          error.message ===
            "Order is not in NEW status. Cannot add or update items." &&
            navigate("/payment-in-progress-block");
        } finally {
          // Hide global loader
          dispatch(handleGlobalLoader(false));
          handleCloseModal();
        }
      } else if (type === "EDIT") {
        if (
          handleValuesAreDiff(prevSelectedData, {
            ...selectedMenuCustomizationOptions,
            quantity: menuQuantity,
          })
        ) {
          if (setAddItemQuantity) {
            setAddItemQuantity(menuQuantity);
            dispatch(handleMenuItemsBannerType("EDIT"));
            dispatch(handleMenuItemBannerDisplay(true));
          }

          updateOrderItem({
            orderItemId: editData?.id,
            orderItemBody: customizedMenuItemPayload,
          }).then((res: any) => {
            handleCloseModal();
            if (res?.data) {
              console.log("[edit menu custom] res?.data :>> ", res?.data);
            } else {
              const errMessage =
                res?.error?.data?.message ??
                res?.error?.error ??
                res?.error?.data?.error ??
                "Something went wrong while updating your order. Please try again!";
              console.error("Error during EDIT operation: ", res);
              setToastMessage(errMessage);
              errMessage ===
                "Order is not in NEW status. Cannot add or update items." &&
                navigate("/payment-in-progress-block");
            }
            dispatch(handleGlobalLoader(false));
          });
        } else {
          dispatch(handleGlobalLoader(false));
          handleCloseModal();
          // toast.info("There was no change made for your preferences !");
        }
      }

      dispatch(handleGlobalLoader(false));
    };

    const menuItemImage = handleValidImageUrl(
      menuDetailData?.productMaster?.images
    );

    // Clear The Toast Message when the toast is removed
    const handleOnToastDismiss = () => {
      setToastMessage(null);
    };

    // Render the toast message if available
    const renderToast = () => {
      if (toastMessage) {
        return (
          <CustomToast
            message={toastMessage}
            handleOnToastDismiss={handleOnToastDismiss}
          />
        );
      }
      return null;
    };

    // Handle touch events
    useEffect(() => {
      const handleTouchStart = (e: TouchEvent) => {
        const menu = modalRef.current;
        if (!menu) return;

        const touch = e.touches[0];
        const menuRect = menu.getBoundingClientRect();
        const isInsideMenu =
          touch.clientX >= menuRect.left &&
          touch.clientX <= menuRect.right &&
          touch.clientY >= menuRect.top &&
          touch.clientY <= menuRect.bottom;

        if (!isInsideMenu) {
          e.preventDefault();
        }

        touchStartY.current = touch.clientY;
      };

      const handleTouchMove = (e: TouchEvent) => {
        const menu = modalRef.current;
        if (!menu) return;

        const touch = e.touches[0];
        const menuRect = menu.getBoundingClientRect();
        const isInsideMenu =
          touch.clientX >= menuRect.left &&
          touch.clientX <= menuRect.right &&
          touch.clientY >= menuRect.top &&
          touch.clientY <= menuRect.bottom;

        if (!isInsideMenu) {
          e.preventDefault();
          return;
        }

        const touchY = touch.clientY;
        const scrollTop = menu.scrollTop;
        const scrollHeight = menu.scrollHeight;
        const clientHeight = menu.clientHeight;

        // Check if we're at the top or bottom of the menu
        const isAtTop = scrollTop <= 0;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;

        // Determine scroll direction
        const isScrollingUp = touchY > touchStartY.current;
        const isScrollingDown = touchY < touchStartY.current;

        // Prevent body scroll when at boundaries
        if ((isAtTop && isScrollingUp) || (isAtBottom && isScrollingDown)) {
          e.preventDefault();
        }
      };
      document.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      document.addEventListener("touchmove", handleTouchMove, { passive: false });

      return () => {
        document.removeEventListener("touchstart", handleTouchStart);
        document.removeEventListener("touchmove", handleTouchMove);
      };
    }, []);

    return (
      <Modal
        width={"100vw"}
        closable={false}
        open={true}
        onCancel={handleCloseModal}
        footer={null}
        className="repeatCustomizationModal"
        style={{
          padding: 0,
          margin: 0,
          borderRadius: "999px",
          maxWidth: "100vw",
        }}
        getContainer={false}
      >
        <div className="repeatCustomizationModal">
          {(menuDetailIsLoading || menuDetailIsFetching) && (
            <CustomLoader
              isLoading={menuDetailIsLoading || menuDetailIsFetching}
            />
          )}
          <div
            className="relative menuCustomizationModalContainer flex flex-col overflow-y-auto justify-between gap-1"
            ref={modalRef}
          >
            <div>
              <div
                className={`menuCustomizationModalHeaderContainer flex items-center justify-between p-4 sticky top-0 z-10`}
              >
                <div className={`menuCustomizationModalHeader capitalize `}>
                  {convertToCapitalizedString(
                    menuDetailData?.displayName ??
                    menuDetailData?.productMaster?.itemName
                  )}
                </div>
                <button onClick={handleCloseModal}>
                  <ModalClose className="iconColor" />
                </button>
              </div>
              {menuDetailIsSuccess && menuDetailData && (
                <div className="flex flex-col">
                  <div>
                    <div>
                      {menuItemImage ? (
                        <div className="h-full flex flex-col justify-start items-center relative pb-4">
                          <img
                            src={menuItemImage}
                            alt={
                              menuDetailData?.displayName ??
                              menuDetailData?.productMaster?.itemName
                            }
                            className="menuCustomizationModalImage"
                          />
                          <div className="absolute bottom-6 flex flex-row w-full items-center justify-start gap-2 px-2 whitespace-nowrap overflow-x-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                            {menuDetailData?.calories && (
                              <div className="menuCustomizationTags py-[2px] px-2 rounded-xl ">
                                {menuDetailData?.calories} Kcal
                              </div>
                            )}
                            {menuDetailData?.tag?.length &&
                              menuDetailData?.tag?.map((tag: any) =>
                                tag?.id ? (
                                  <div className="menuCustomizationTags py-[2px] px-2 rounded-xl">
                                    {convertToCapitalizedString(tag?.name)}
                                  </div>
                                ) : null
                              )}
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-row w-full items-center justify-start gap-2 px-2 whitespace-nowrap overflow-x-scroll [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                          {menuDetailData?.calories && (
                            <div className="menuCustomizationTags py-[2px] px-2 rounded-xl">
                              {menuDetailData?.calories} Kcal
                            </div>
                          )}
                          {menuDetailData?.tag?.length &&
                            menuDetailData?.tag?.map((tag: any) =>
                              tag?.id ? (
                                <div className="menuCustomizationTags py-[2px] px-2 rounded-xl">
                                  {convertToCapitalizedString(tag?.name)}
                                </div>
                              ) : null
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="px-3">
                    <div>
                      <div
                        className={`pt-3 pb-5 menuCustomizationModalDescription`}
                      >
                        {menuDetailData?.description}
                      </div>
                      <div className="flex flex-col gap-2">
                        {componentPropDetails.map(
                          (item, index) =>
                            handleCustomizationSectionData(item.mainHeader)
                              .length > 0 && (
                              <div key={index} className="pt-[10px] first:pt-0">
                                <MenuItemCustomizationCheckbox
                                  editData={item.editData}
                                  bodyHeader={item.bodyHeader}
                                  isRequired={item.isRequired}
                                  mainHeader={item.mainHeader}
                                  checkBoxType={item.checkBoxType}
                                  options={handleCustomizationSectionData(
                                    item.mainHeader
                                  )}
                                  maxValue={item.maxValue}
                                  minValue={item.minValue}
                                />
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {renderToast()}
            </div>
            <div className="sticky bottom-0 inset-x-0 menuCustomizationFooter w-full flex items-center justify-between py-1 px-3 gap-3">
              <CounterButton
                initialQuantity={menuQuantity}
                onQuantityChange={handleQuantityUpdate}
              />
              <button
                disabled={disableAddButton || menuQuantity === 0}
                onClick={handleSubmit}
                className={`flex items-center w-full footerAddItemButton py-4 px-3 justify-around ${(disableAddButton || menuQuantity === 0) && "opacity-30"
                  }`}
              >
                {type === "EDIT" ? "Edit Item" : "Add Item"}
                {selectedPrice !== 0 && (
                  <div className="pl-3">{rupeeFormatter(selectedPrice)}</div>
                )}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  };

const handleSelectedPrice = (
  obj: any,
  mainMenuQuantity: number,
  minMaxModValue: {
    minValue: number | null;
    maxValue: number | null;
  } | null
): number => {
  let total = 0;

  // Check if obj is an array
  if (Array.isArray(obj)) {
    // Sum up prices in the array
    obj.forEach((item) => {
      if (item.checked && typeof item.price === "number") {
        if (item.quantity === 0) {
          total += item.price;
        } else {
          total += item.price * item.quantity;
        }
      }
    });
  } else if (typeof obj === "object" && obj !== null) {
    // Recursively iterate through object properties
    Object.entries(obj).forEach(([key, value]) => {
      if (key !== "recommendations") {
        // Exclude recommendations
        total += handleSelectedPrice(value, 1, minMaxModValue); // Recursive call with mainMenuQuantity = 1
      }
    });
  }

  // Return total multiplied by mainMenuQuantity (only at the top level)
  return total * mainMenuQuantity;
};

export default MenuItemCustomizationModal;
