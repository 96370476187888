import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// Define a type for the slice state
interface HeaderState {
  isConfirmationModalOpen: boolean;
  disableConfirmedTab: boolean;
  defaultHeaderLogo:string;
  headerLogo:string;
  altName:string | null;
}

// Define the initial state using that type
const initialState: HeaderState = {
  isConfirmationModalOpen: false,
  disableConfirmedTab: true,
  defaultHeaderLogo: "/logos/default.png",
  headerLogo: "",
  altName:null,
};

// Create the slice
const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    handleHeaderConfirmationModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmationModalOpen = action.payload;
    },
    handleConfirmedTabDisableState: (state, action: PayloadAction<boolean>) => {
      state.disableConfirmedTab = action.payload;
    },
    handleHeaderLogo:(state, action: PayloadAction<{defaultHeaderLogo:string,headerLogo:string,altName:string}>)=>{
      state.defaultHeaderLogo = action.payload.defaultHeaderLogo
      state.headerLogo = action.payload.headerLogo
      state.altName=action.payload.altName
    }
  },
});

// Export the actions
export const {
  handleHeaderConfirmationModalOpen,
  handleConfirmedTabDisableState,
  handleHeaderLogo
} = headerSlice.actions;

// Export the reducer
export default headerSlice.reducer;
